import Url from 'url';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";

import { setReturnTo } from '../../../../../actions/return';

import { withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import Api from '../../../../../common/api'
import Local from '../../../../../common/local'
import Util from '../../../../../common/util';

import AuthenticationView from './views/authentication-view';
import ChangeUserView from './views/change-user-view';
import RedirectView from './views/redirect-view';
import ReconcileView from './views/reconcile-view';
import UpgradeView from './views/upgrade-view';
import AuthInitiationRequestView from './views/auth-initiation-request-view';
import TwoFactorView from './views/two-factor-view';
import GeneralControlsView from './views/general-controls-view';

const styles = theme => ({
    pageContainer: theme.pageContainer
});

class HomeApp extends Component {

    constructor(props) {

        super(props);

        this.state = {
            redirect: null,
            error: function () {
                if (this.props.query.logged_out === 'true') {
                    return 'You have been logged out. Please re-authenticate to continue.'
                }
                if (this.props.query.pass_update === 'true') {
                    return 'Your password reset was successful! Login now!';
                }
                if (this.props.query.verified === 'true') {
                    return 'Your account was succesfully merged! Login to proceed.';
                }
                return null;
            }.bind(this)(),
            changingUser: false,
            critical: false,
            pixel: this.props.query.pixel === 'true',
            postMessage: this.props.query.postMessage === 'true',
            identity: this.props.query.identity || null,
            upgrade: false,
            upgradeUsername: '',
            reconcile: false,
            reconcileUsername: '',
            twoFactor: false,
            twoFactorUsername: '',
            twoFactorPassword: '',
            initiateForgot: false,
            initiateForgotUsername: '',
            stopInitiate: false,
            errorOpen: function () {

                return this.props.query && (this.props.query.logged_out === 'true' || this.props.query.pass_update === 'true' || this.props.query.verified === 'true');
            }.bind(this)(),
            logout: this.props.query.logged_out === 'true',
            authorized: Local.hasToken(),
            reauthenticate: this.props.query.reauthenticate === 'true' && Local.hasToken(),
            isAuthInitiationRequest: Util.isAuthInitiationRequest(this.props.query),
            denied: false,
            user: null
        };

        this.api = new Api();
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onDeny = this.onDeny.bind(this);
        this.onError = this.onError.bind(this);
        this.onErrorClose = this.onErrorClose.bind(this);
        this.onOauthLoggedOut = this.onOauthLoggedOut.bind(this);
        this.onRequiresReconciliation = this.onRequiresReconciliation.bind(this);
        this.onUpgradeAccount = this.onUpgradeAccount.bind(this);
        this.onShowAvailableUsers = this.onShowAvailableUsers.bind(this);
        this.onSwitchUser = this.onSwitchUser.bind(this);
        this.onTwoFactor = this.onTwoFactor.bind(this);
        this.onTwoFactorSuccess = this.onTwoFactorSuccess.bind(this);
        this.onRequireResetPassword = this.onRequireResetPassword.bind(this);
        this.onVerificationSuccess = this.onVerificationSuccess.bind(this);
        this.postMessage = this.postMessage.bind(this);
    }

    onLoginSuccess(token, username='') {

        if (token) {
            Local.userLogin(token, username);
        }

        if (!Local.hasToken()) {
            return this.setState({
                logout: false,
                twoFactor: false,
                error: (
                    <div>
                        <p>We're sorry, our servers are not available right now.</p>
                        <p>Please try logging in again shortly.</p>
                    </div>
                )
            })
        }

        return this.setState({
            changingUser: false,
            logout: false,
            twoFactor: false,
            authorized: true,
            reauthenticate: false
        });
    }

    onLogout() {

        return this.setState({ authorized: Local.hasToken() });
    }

    onApprove(grant) {
        const parsed = decodeURIComponent(this.props.query.redirect_uri);
        const parts = Url.parse(parsed);
        const queryParameters = (parts.query ? parts.query.split('&') : []);

        if (!grant)
          return this.postMessage(null, this.props.query.state, null);

        queryParameters.push(`code=${grant.code}`, `state=${grant.state}`);

        let redirect = `${parts.protocol}//${parts.host}`;

        if (parts.pathname) redirect += `${parts.pathname}`;

        redirect += `?${queryParameters.join('&')}`;

        if (this.state.postMessage || this.state.pixel) {
          return this.postMessage(grant.code, grant.state, redirect);
        }

        return this.setState({ redirect });
    }

    onDeny(applicationName) {

        return this.setState({
            denied: true,
            errorOpen: true,
            error: `You have denied "${applicationName}" access to your account. You can always try logging in again from the site that brought you here.`
        });
    }

    onOauthLoggedOut() {

        if (Local.getCurrentUser()) {
          Local.userLogout(Local.getCurrentUser().username);
        }

        if (this.state.pixel) {
            return this.postMessage(null, this.props.query.state, null);
        }

        return this.setState({
            logout: true,
            authorized: false
        });
    }

    onError(e) {

        return this.setState({ errorOpen: true, error: e.error, critical: e.critical || false });
    }

    onErrorClose() {

        return this.setState({ errorOpen: false });
    }

    onRequiresReconciliation(username) {

        return this.setState({
            authorized: false,
            stopInitiate: true,
            reconcile: true,
            twoFactor: false,
            reconcileUsername: username
        });
    }

    onUpgradeAccount(username) {

        this.setState({ upgrade: true, twoFactor: false, upgradeUsername: username });
    }

    onShowAvailableUsers() {

        this.setState({changingUser: true});
    }

    async onSwitchUser(username) {
      await Local.switchUser(username);

      return this.setState({
          changingUser: false,
          logout: false,
          twoFactor: false,
          authorized: true,
          reauthenticate: false
      });
    }

    onTwoFactor(username, password) {

        this.setState({ twoFactor: true, twoFactorUsername: username, twoFactorPassword: password });
    }

    onTwoFactorSuccess(token, username='') {

        Local.userLogin(token, username);

        return this.setState({
            twoFactor: false,
            authorized: !!token
        });
    }

    onRequireResetPassword(initiateForgotUsername) {

        return this.setState({
            initiateForgot: true,
            initiateForgotUsername
        });
    }

    onVerificationSuccess() {

        return this.setState({
            authorized: true,
            stopInitiate: false,
            reconcile: false
        });
    }

    postMessage(code, state, uri) {
        let callTo = window.parent;
        if (window.opener) callTo = window.opener;

        return callTo.postMessage({
          code: code,
          state: state,
          uri: uri
        }, '*');
    }

    wrapWithAlert(comp) {

        return (
            <Grid container spacing={0} justifyContent="center">
                <Snackbar
                    open={this.state.errorOpen}
                    autoHideDuration={5000}
                    message={this.state.error}
                    onClose={this.onErrorClose}
                    action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.onErrorClose}
                        >
                          <CloseIcon />
                        </IconButton>
                    ]} />
                {comp}
            </Grid>
        );
    }

    async componentDidMount() {

        if (this.props.query.forceLogout === 'true' || this.props.query.logged_out === 'true') {
            this.onOauthLoggedOut();
            return;
        }

        if (this.state.pixel && !this.state.authorized) {
            return this.postMessage(null, this.props.query.state, null);
        }

        if (this.props.query.reauthenticate === 'true') {
            try {
                const user = await this.api.get('/user');
                if (!user || user.code !== 200) {
                    return this.setState({ reauthenticate: false });
                }

                return this.setState({ user: user.content });
            }
            catch (e) {
                return this.setState({ error: e.toString() });
            }
        }
    }

    render() {

        let view;

        if (this.state.redirect) {
            view = (
                <RedirectView redirectTo={this.state.redirect} />
            );
        }
        else if (this.state.changingUser) {
          view = this.wrapWithAlert(
              <ChangeUserView onChangeUser={this.onSwitchUser} />
          );
        }
        else if (!this.state.authorized || this.state.reauthenticate) {
            if (this.state.upgrade) {
                view = this.wrapWithAlert(
                    <UpgradeView query={this.props.query}
                                 username={this.state.upgradeUsername}
                                 onError={this.onError}
                                 onLoginSuccess={this.onLoginSuccess} />
                );
            }
            else if (this.state.reconcile) {
                view = this.wrapWithAlert(
                    <ReconcileView clientId={this.props.query.client_id}
                                   onError={this.onError}
                                   username={this.state.reconcileUsername}
                                   onVerificationSuccess={this.onVerificationSuccess} />
                );
            }
            else if (this.state.initiateForgot) {
                const query = Object.assign(this.props.query, { aslogin: this.state.initiateForgotUsername });
                const serialized = Util.toQueryString(query);

                window.location.href = `/forgot${serialized}`;
            }
            else if (this.state.twoFactor) {
                view = this.wrapWithAlert(
                    <TwoFactorView
                        clientId={this.props.query.client_id}
                        query={this.props.query}
                        username={this.state.twoFactorUsername}
                        password={this.state.twoFactorPassword}
                        onLoginSuccess={this.onLoginSuccess}
                        onUpgradeAccount={this.onUpgradeAccount}
                        onTwoFactor={this.onTwoFactor}
                        onRequiresReconciliation={this.onRequiresReconciliation}
                        onError={this.onError} />
                );
            }
            else {
                view = this.wrapWithAlert(
                    <AuthenticationView clientId={this.props.query.client_id}
                                        query={this.props.query}
                                        user={this.state.user}
                                        isReauthenticationRequest={this.state.reauthenticate}
                                        loggedOut={this.state.logout}
                                        onLoginSuccess={this.onLoginSuccess}
                                        onUpgradeAccount={this.onUpgradeAccount}
                                        onTwoFactor={this.onTwoFactor}
                                        onRequiresReconciliation={this.onRequiresReconciliation}
                                        onRequireResetPassword={this.onRequireResetPassword}
                                        onShowAvailableUsers={this.onShowAvailableUsers}
                                        onError={this.onError} />
                );
            }
        }
        else if (this.state.isAuthInitiationRequest && this.state.denied === false && this.state.critical === false) {
            if (this.state.reconcile) {
                view = this.wrapWithAlert(
                    <ReconcileView clientId={this.props.query.client_id}
                                   onError={this.onError}
                                   username={this.state.reconcileUsername}
                                   onVerificationSuccess={this.onVerificationSuccess} />
                );
            }
            else if (this.state.identity) {
              const newQuery = Object.assign(this.props.query, {});
              const redirect = `/identity${Util.toQueryString(newQuery)}`;

              view = (
                <RedirectView redirectTo={redirect} />
              );
            }
            else {
                view = this.wrapWithAlert(
                    <AuthInitiationRequestView open={!this.state.stopInitiate}
                                               onApprove={this.onApprove}
                                               onDeny={this.onDeny}
                                               onLogout={this.onOauthLoggedOut}
                                               onError={this.onError}
                                               onRequiresReconciliation={this.onRequiresReconciliation}
                                               onShowAvailableUsers={this.onShowAvailableUsers}
                                               pixel={this.state.pixel}
                                               query={this.props.query} />
                );
            }
        }
        else {
          if (this.props.returnTo) {
            const returnTo = this.props.returnTo;
            this.props.dispatch(setReturnTo(null));
            view = <Redirect push to={returnTo} />;
          } else {
            view = this.wrapWithAlert(
                <GeneralControlsView onLogout={this.onOauthLoggedOut} onShowAvailableUsers={this.onShowAvailableUsers}/>
            );
          }
        }

        return (
            <div className={this.props.classes.pageContainer}>
                {view}
            </div>
        );
    }
}

// export default withStyles(styles)(HomeApp);

const mapStateToProps = state => {
  return {
    returnTo: state.returnTo
  };
}

const ConnectedPage = connect(mapStateToProps, null)(HomeApp);

export default withStyles(styles)(ConnectedPage);
