import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import PeopleIcon from '@material-ui/icons/People';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

import Api from '../../../../../../common/api'
import Auth from '../../../../../../common/auth'
import Local from '../../../../../../common/local'
import Util from '../../../../../../common/util';
import Recaptcha from '../../../../../../common/components/recaptcha';

import CurrentUser from '../../../../shared/components/current-user';
import Heading from '../../../../shared/components/heading';
import LeftRail from '../../../../shared/components/left-rail';
import PageHeader from '../../../../shared/components/page-header';
import StandardInput from '../../../../shared/components/gn-standard-input';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  button: {
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '16px',
    height: '55px',
    width: '100%',
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px"
  },
  noHeaderContainer: {
    padding: '20px 20px 0px 20px',
    maxWidth: '1200px',
    margin: '0 auto 50px auto'
  }
});

class StepTwoFactor extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      processing: false,
      code: '',
      validation: {
        code: null
      }
    };

    this.api = new Api();
    this.generateOnInput = this.generateOnInput.bind(this);
    this.onError = this.onError.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.startLogin = this.startLogin.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { code: null }
      });
    }.bind(this);
  }

  onError(err) {

    this.setState({ processing: false });
    this.props.onError(err);
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      await this.startLogin();
    }
  }

  async onLogin(recaptcha) {

    const {
        username,
        password
    } = this.props;
    const { code } = this.state;

    const validation = {
        code: code === '' ? 'Error: Please enter your 6-digit code.' : false
    };

    if (code.length !== 6) {
        validation.code = 'Error: Your code must be six digits long.';
    }

    if (Object.values(validation).some((v) => { return v !== false; })) {
        return this.setState({ validation });
    }

    this.setState({ processing: true });

    try {
      const response = await this.api.post(
        `/user/authenticate${this.props.clientId ? `?clientId=${this.props.clientId}` : ''}`,
        {
          username,
          password,
          code,
          recaptcha,
          parameters: Util.toCoreQueryObject(this.props.query)
        }
      );

      if (response.code === 201) {
        this.props.postMessageRaw({ event: '2fa', success: true });
        return this.props.onLoginSuccess(response.content.token);
      }
      else if (response.code >= 400) {
        this.props.postMessageRaw({ event: '2fa', success: false });
        if (response.code === 400) {
          return this.onError({
            error: (
              <div>{response.content.message}</div>
            )
          });
        }
        else if (response.code === 401) {
          return this.onError({
            error: (
              <div>
              <div>We couldn't find a user with that address, or the password didn't match.</div>
              <div>Please check your credentials and try again.</div>
              </div>
            )
          });
        }
        else if (response.code === 423) {
          return this.onError({
            error: (
              <div>
              <div>
              This account has been temporary locked for too many failed login attempts.
              </div>
              <div>
              Please try again in a while, or contact customer support for immediate assistance.
              </div>
              </div>
            )
          });
        }
        else if (response.code === 417){
          return this.onError({
            error: (
              <div>
              <p>Your 2-FA code is either incorrect or expired.</p>
              </div>
            )
          });
        }
        else if (response.code === 426) {
          return this.props.onUpgradeAccount(username);
        }
        else if (response.code === 428) {
          return this.props.onRequiresReconciliation(username);
        }
      }
    }
    catch (e) {
      return this.onError({ error: e.toString() });
    }
  }

  async startLogin() {
    if (window.globals && window.globals.gidRecaptchaKey) {
      const that = this;
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(window.globals.gidRecaptchaKey, {action: 'submit'}).then(async function(token) {
          await that.onLogin(token);
        });
      });
    } else {
      await this.onLogin();
    }
  }

  render() {
    const containerStyle =
      this.props.query.noHeader ?
      this.props.classes.noHeaderContainer :
      this.props.classes.view;

    const emailInput = (
      <Grid item xs={12} md={12}>
        <StandardInput
          pattern="\\d+"
          style={{ marginBottom: '15px' }}
          error={this.state.validation.code}
          attribute="code"
          label="Two-Factor Code"
          labelWidth={100}
          focus={true}
          onChange={this.generateOnInput('code')}
          onKeyUp={this.onSubmitPressed} />
      </Grid>
    );

    const buttonStyle = !this.props.query.buttonColor ? null :
    {
      backgroundColor: `#${this.props.query.buttonColor}`
    };

    const recaptcha = (window.globals && window.globals.gidRecaptchaKey && !this.props.query.noForgot) ?
      <Recaptcha textClassname={this.props.classes.recaptchaText}/> : null;

    const bottomControls = (
      <Grid className={this.props.classes.buttonControls} item xs={12}>
        <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.startLogin} variant="contained" id="login">
          {this.state.processing ?
            (<CircularProgress size={14} color="inherit" />)
            : 'Continue'
          }
        </Button>
      </Grid>
    );

    return (
      <Grid container spacing={0} className={containerStyle} >
        {emailInput}
        {bottomControls}
      </Grid>
    );
  }
}

export default withStyles(styles)(StepTwoFactor);
